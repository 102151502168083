import deCommon from './de-common';
import deBorrower from './de-borrower';
import deLender from './de-lender';
import dePartners from './de-partners.js';
import deIngPartners from './de-ing-partners.js';
import deIngBorrower from './de-ing-borrower.js';
import deIngAmazon from './de-ing-amazon.js';
import deIngSigners from './de-ing-signers.js';
import deIngOffer from './de-ing-offer.js';
import deIngCommon from './de-ing-common';
import deSigners from './de-signers.js';
import deAuthentication from './de-authentication.js';
import deIngAuthentication from './de-ing-authentication.js';
import deIng from './de-ing.js';
import deSubscriptionConfirmation from './de-subscription-confirmation.js';
import deIngSubscription from './de-ing-subscription';
import deIngWaitingList from './de-ing-waiting-list';

export default Object.assign(
  {},
  deCommon,
  deBorrower,
  deLender,
  dePartners,
  deIngPartners,
  deIngBorrower,
  deIngAmazon,
  deIngSigners,
  deIngOffer,
  deIngCommon,
  deSigners,
  deIng,
  deAuthentication,
  deIngAuthentication,
  deSubscriptionConfirmation,
  deIngSubscription,
  deIngWaitingList
);
