export default {
  ingUrl: 'https://www.ing.de',
  infoAtIngEmail: 'info@ing-diba.de',
  serviceEmail: 'service-business@ing.de',
  businessEmail: 'business@ing.de',
  applicationEmail: 'antrag@ing.de',
  dataProtectionUrl: 'https://ing.de/dokumente/datenschutz-geschaeftskunden/',
  privacyPolicyUrl:
    'https://www.ing.de/binaries/content/assets/pdf/hilfe/datenschutz/datenschutzerklaerung-der-ing-diba-ag-fuer-geschaeftskunden.pdf',
  businessBankingUrl: 'https://ing.de/business',
  newsletterConfirmationSuccessUrl:
    'https://ing.de/business/anmeldung/newsletter/bestaetigung',
  newsletterConfirmationFailureUrl:
    'https://ing.de/business/anmeldung/newsletter/fehler',
  waitingListConfirmationSuccessUrl:
    'https://www.ing.de/business/anmeldung/produkt-neuigkeiten/bestaetigung',
  waitingListConfirmationFailureUrl:
    'https://www.ing.de/business/anmeldung/produkt-neuigkeiten/fehler',

  schufaPrivacyUrl: 'https://www.ing.de/datenschutz/schufa',
  schufaUrl: 'https://www.schufa.de/',
  creditReformPrivacy:
    'https://www.creditreform.de/frankfurt-am-main/datenschutz',
  theFooter: {
    cookieSettings: '#uc-corner-modal-show',
    termsAndConditions: 'https://www.ing.de/agb/',
    privacyPolicy:
      'https://www.ing.de/binaries/content/assets/pdf/hilfe/datenschutz/datenschutzerklaerung-der-ing-diba-ag-fuer-geschaeftskunden.pdf',
    imprintUrl: 'https://www.ing.de/ueber-uns/unternehmen/impressum',
  },
  theContact: {
    amazon: {
      contact: {
        email: 'unternehmen@ing.de',
        phone: '030 / 800 935 230',
      },
    },
    bridge: {
      contact: {
        email: 'business@ing.de',
        phone: '030 / 398 205 269',
      },
    },
    partner: {
      contact: {
        email: 'partneranfrage@ing.de',
        phone: '030 / 229 572 84',
      },
    },
    partnerRegister: {
      contact: {
        email: 'partner-bb@ing.de',
        phone: '030 / 229 572 81',
      },
    },
  },
};
