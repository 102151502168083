<template>
  <span>&nbsp;</span>
</template>
<script>
  import { confirmWaitingListRegistration } from '@/webservice/subscription';

  export default {
    name: 'WaitingListConfirmation',
    async mounted() {
      const {
        waitingListConfirmationSuccessUrl,
        waitingListConfirmationFailureUrl,
      } = this.$translationConstantValues;

      const token = this.$route.query?.token;
      let succeed = true;
      if (token) {
        try {
          await confirmWaitingListRegistration({ token });
          window.location.replace(waitingListConfirmationSuccessUrl);
        } catch (error) {
          succeed = false;
        }
      }

      if (!token || !succeed) {
        window.location.replace(waitingListConfirmationFailureUrl);
      }
    },
  };
</script>
