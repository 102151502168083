<template>
  <div class="subscription-section">
    <form
      v-if="!formSuccessfullySent"
      method="post"
      accept-charset="utf-8"
      @submit.prevent="onSubmit"
    >
      <IngFormLoader :manuallyShow="showLoader" class="loader-style" />

      <PageSection>
        <template #section-content>
          <div class="subscription-section__content">
            <h4 class="subscription-section__title">
              {{ $t('waitingListPage.form.title') }}
            </h4>

            <div class="subscription-section__subtitle">{{
              $t('waitingListPage.form.para1')
            }}</div>

            <div class="subscription-section__subtitle">{{
              $t('waitingListPage.form.para2')
            }}</div>
            <div class="subscription-section__subtitle">{{
              $t('waitingListPage.form.para3')
            }}</div>
            <div class="subscription-section__form">
              <TextInput
                v-model="subscription.email"
                :error="getError('email')"
                :displayErrorIfDirty="!submitClicked"
                :label="$t('waitingListPage.form.email.label')"
                :name="$t('waitingListPage.form.email.label')"
                inputmode="email"
              />
              <TextInput
                v-model="subscription.firstName"
                :error="getError('firstName')"
                :displayErrorIfDirty="!submitClicked"
                :label="$t('waitingListPage.form.firstName.label')"
                :name="$t('waitingListPage.form.firstName.label')"
                inputmode="firstName"
              />
              <TextInput
                v-model="subscription.lastName"
                :error="getError('lastName')"
                :displayErrorIfDirty="!submitClicked"
                :label="$t('waitingListPage.form.lastName.label')"
                :name="$t('waitingListPage.form.lastName.label')"
                inputmode="lastName"
              />
              <TextInput
                v-model="subscription.company"
                :error="getError('company')"
                :displayErrorIfDirty="!submitClicked"
                :label="$t('waitingListPage.form.companyName.label')"
                :name="$t('waitingListPage.form.companyName.label')"
                inputmode="company"
              />
              <Dropdown
                v-model="subscription.legalStatus"
                name="legalStatus__c"
                :value="subscription.legalStatus"
                :options="legalStatusOptions"
                maxVisibleOptions="5"
                :label="$t('waitingListPage.form.legalForm.label')"
                :error="getError('legalStatus')"
                :displayErrorIfSubmitted="displayError('legalStatus')"
                data-qa-id="legal-form"
              />
              <FieldStatus
                v-if="errorMessage"
                :error="errorMessage"
                class="general-error-container"
              />
              <br />
              <IngButton type="submit">
                {{ $t('waitingListPage.form.submit') }}
              </IngButton>
            </div>
            <div class="subscription-section__subtitle">{{
              $t('waitingListPage.form.para4')
            }}</div>
            <div class="subscription-section__more-info">
              <i18n path="waitingListPage.form.para5.label" tag="div">
                <a
                  :href="$translationConstantValues.dataProtectionUrl"
                  place="linkText"
                  target="_blank"
                >
                  {{ $t('waitingListPage.form.para5.linkText') }}</a
                >
              </i18n>
            </div>
          </div>
        </template>
      </PageSection>
    </form>
    <div v-else>
      <PageSection>
        <template #section-content>
          <div class="subscription-section__content">
            <h4 class="subscription-section__title">
              {{ $t('waitingListPage.success.header.title') }}
            </h4>

            <div class="subscription-section__subtitle">
              <i18n path="waitingListPage.success.body.part1.text" tag="div">
                <em place="boldContent">
                  {{ $t('waitingListPage.success.body.part1.boldContent') }}
                </em>
              </i18n>
            </div>

            <div class="subscription-section__subtitle">
              <i18n path="waitingListPage.success.body.part2.text" tag="div">
                <em place="boldContent">
                  {{ $t('waitingListPage.success.body.part2.boldContent') }}
                </em>
              </i18n>
            </div>

            <div class="subscription-section__subtitle">
              <i18n path="waitingListPage.success.body.part3.label" tag="div">
                <a
                  place="linkText"
                  :href="$translationConstantValues.businessBankingUrl"
                  target="_blank"
                >
                  {{ $t('waitingListPage.success.body.part3.linkText') }}</a
                >
              </i18n>
            </div>
          </div>
        </template>
      </PageSection>
    </div>
  </div>
</template>

<script>
  import findKey from 'lodash/findKey';

  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import IngFormLoader from '@/components/common/forms/ing-form-loader';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import dropdownOptionsMixin from '@/mixins/dropdownOptionsMixin';
  import Dropdown from '@/components/ingOrangeJuice/Base/Dropdown';
  import { subscriptionValidator } from '@/validators';
  import { registerWaitingList } from '@/webservice/subscription';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';

  export default {
    name: 'WaitingListRegistration',
    components: {
      TextInput,
      IngButton,
      IngFormLoader,
      PageSection,
      Dropdown,
      FieldStatus,
    },
    mixins: [dropdownOptionsMixin],

    data() {
      return {
        subscription: {
          email: '',
          firstName: '',
          lastName: '',
          company: '',
          legalStatus: '',
        },
        submitClicked: false,
        errors: {},
        serverError: '',
        showLoader: false,
        formSuccessfullySent: false,
      };
    },
    computed: {
      errorMessage() {
        return this.serverError;
      },
    },
    watch: {
      subscription: {
        deep: true,
        handler() {
          this.validateForm();
        },
      },
    },
    mounted() {
      this.validateForm();
    },
    methods: {
      async onSubmit() {
        this.submitClicked = true;
        if (this.formDataIsValid()) {
          try {
            this.showLoader = true;
            const body = {
              email: this.subscription.email,
              firstName: this.subscription.firstName,
              lastName: this.subscription.lastName,
              companyName: this.subscription.company,
              legalForm: this.subscription.legalStatus,
              listType: 'current_account',
            };
            await registerWaitingList(body);
            this.formSuccessfullySent = true;
          } catch (error) {
            this.serverError = this.$t('forms.signupForm.errors.500');
          } finally {
            this.showLoader = false;
          }
        }
      },
      validateForm() {
        this.errors = subscriptionValidator(this.subscription);
      },
      formDataIsValid() {
        return !findKey(this.errors);
      },
      getError(field) {
        return (
          this.errors[field] && this.$t('forms.errors.' + this.errors[field])
        );
      },
      displayError(field) {
        return this.submitClicked && !!this.errors[field];
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  .subscription-section {
    &__content {
      @include mq-size(m) {
        width: 89.6%;
      }
      @include mq-size(xl) {
        width: 69.3%;
      }
    }
    &__form {
      padding-top: px2rem(40px);
      @include mq-size(s) {
        width: 86.6%;
      }
      @include mq-size(m) {
        width: 57.1%;
      }
      @include mq-size(xl) {
        width: 48.8%;
      }
    }
    &__title {
      font-size: px2rem(36px);
      line-height: px2rem(48px);
      color: $Primary-Orange;
      font-weight: normal;
      flex: 0 0 100%;
      margin: 0 0 px2rem(18px) 0;
    }
    &__subtitle {
      color: $Primary-Grey600;
      font-size: px2rem(19px);
      line-height: px2rem(28px);
      font-weight: 400;
      margin-top: px2rem(14px);
      display: block;
    }
    &__more-info {
      margin: px2rem(80px) 0;
    }
  }
  .bold-text {
    font-weight: 700;
  }
</style>
