<template>
  <div class="ing-wrapper">
    <TheHeader />
    <PageSection
      class="section-form section-form--full-height"
      sectionPadding="no"
    >
      <template #section-content>
        <WaitingList />
      </template>
    </PageSection>
    <TheFooter />
  </div>
</template>

<script>
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  import WaitingList from '@/components/subscription/WaitingList';
  export default {
    name: 'WaitingListSubscriptionPage',
    components: {
      TheHeader,
      PageSection,
      WaitingList,
      TheFooter,
    },
    metaInfo() {
      return {
        title: this.$i18n.t('waitingListPage.meta.title'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('waitingListPage.meta.title'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('waitingListPage.meta.description'),
          },
        ],
      };
    },
  };
</script>
<style scoped lang="scss">
  .section-form {
    &--full-height {
      flex-grow: 1;
    }
  }
</style>
