export default {
  formFields: {
    optional: '(optional)',
    dropdownPlaceHolder: '– Bitte auswählen –',
    company: {
      placeholder: 'Geben Sie hier Ihren Firmennamen ein.',
      lookup: {
        label: 'Firmensuche',
        info: {
          text: '{boldText} Wenn Sie Ihr Unternehmen aus der Liste auswählen, werden Ihre Firmendaten automatisch vervollständigt.',
          boldText: 'Zeit sparen bei der Kreditanfrage:',
        },
        keepTyping: 'Bitte geben Sie mindestens drei Zeichen ein.',
        loading: 'Einen Moment bitte...',
        wrongCompanyName:
          'Leider konnten wir Ihr Unternehmen nicht finden.{newline}Bitte {switchToManualMode}.',
        noCompanyFound: {
          text: '{boldText} Präzisieren Sie Ihre Suche oder {switchToManualMode}',
          boldText: 'Ihr Unternehmen ist nicht dabei?',
        },
        switchToManualMode: 'geben Sie Ihren Firmennamen manuell ein',
      },
      manual: {
        label: 'Firmenname',
        info: {
          text: 'Automatisch ausfüllen mit der Firmensuche? {switchToLookupMode}',
          switchToLookupMode: 'Hier kommen Sie zurück.',
        },
      },
    },
    companyName: {
      label: 'Firmenname',
    },
    companyTaxNumber: {
      label: 'Steuernummer',
    },
    street: {
      label: 'Straße und Hausnummer',
    },
    companyDateFounded: {
      label: 'Gründungsdatum ({maskFormat})',
    },
    companyRevenue: {
      label: 'Umsatz des letzten Jahres',
      radioButton: {
        after50K: 'Mehr als 50.000 EUR',
        before50K: 'Weniger als 50.000 EUR',
      },
    },
    companyLegalStatus: {
      label: 'Rechtsform',
      dropdown: {
        gmbh: 'GmbH',
        gmbhAndCoKg: 'GmbH & Co. KG',
        freeProfession: 'Freie Berufe',
        individualCompany: 'Einzelfirma',
        kg: 'KG',
        ohg: 'OHG',
        gbr: 'GbR',
        ag: 'AG',
        ugLimitedLiability: 'UG (haftungsbeschränkt)',
        limited: 'Limited',
        other: 'Andere',
      },
      infoMessage:
        'Momentan bieten wir für diese Rechtsform leider keine Finanzierung an.',
    },
    postcode: {
      label: 'PLZ',
    },
    city: {
      label: 'Stadt',
    },
    loanPurpose: {
      label: 'Verwendungszweck',
      dropdown: {
        workingCapital: 'Umlaufvermögen',
        debtRefinancing: 'Umschuldung',
        assetPurchase: 'Investition in Anlagevermögen',
        expansionGrowthFinancing: 'Wachstumskapital',
        debtFinancing: 'Schuldenfinanzierung',
        otherPurposes: 'Sonstige Zwecke',
      },
    },
    bookkeepingStandard: {
      label: 'Bilanzierungsmethode',
      radioButton: {
        balanceSheet: 'Bilanz (HGB/Steuerrecht)',
        incomeStatement: 'Einnahmenüberschussrechnung',
        unknown: 'Unbekannt',
      },
    },
    iban: {
      label: 'IBAN',
    },
    bic: {
      label: 'BIC',
    },
    salutation: {
      label: 'Anrede',
      radioButton: {
        male: 'Herr',
        female: 'Frau',
      },
    },
    title: {
      dr: 'Dr.',
      prof: 'Prof.',
      ing: 'Ing.',
      dlp_ing: 'Dipl.-Ing.',
      Sonstiges: 'Sonstiges',
    },
    firstName: {
      label: 'Vorname',
    },
    lastName: {
      label: 'Nachname',
    },
    dateOfBirth: {
      label: 'Geburtsdatum ({maskFormat})',
    },
    nationality: {
      label: 'Staatsangehörigkeit',
    },
    mobilePhone: {
      label: 'Mobilfunknummer',
    },
    email: {
      label: 'E-Mail-Adresse',
      emailInfo: {
        registerForm:
          'Gut zu wissen: Wir nutzen Ihre E-Mail-Adresse, um Ihnen Information zu unseren Produkten und Services zu senden. Dem können Sie jederzeit, z.B. per E-Mail an {linkTo}, widersprechen',
        applicationForm:
          'Gut zu wissen: Wir nutzen Ihre E-Mail-Adresse, um Ihnen Information zu Ihrem Produkt zu senden und Sie auf weitere Produkte und Services aufmerksam zu machen. Dem können Sie jederzeit, z.B. per E-Mail an {linkTo}, widersprechen.',
        partnerApplicationForm:
          'Gut zu wissen: Wir können die angegebene E-Mail-Adresse nutzen, um Ihren Kunden Information zu unseren Produkten und Services zu senden. Dem können Ihre Kunden jederzeit, z.B. per E-Mail an {linkTo}, widersprechen.',
      },
    },
    address: {
      label: 'Adresszusatz {optional}',
    },
    gender: {
      label: 'Anrede',
      radioButton: {
        male: 'Herr',
        female: 'Frau',
      },
    },
    submitButton: {
      label: 'Firmenkredit anfragen',
    },
    loginButton: {
      label: 'Anmelden',
    },
    registerPartnerButton: {
      label: 'Jetzt registrieren',
    },
  },
  theFooter: {
    links: {
      cookieSettings: {
        text: 'Cookie-Einstellungen',
        ariaLabel: 'Gehe zu Cookie-Einstellungen',
      },
      termsAndConditions: {
        text: 'AGB',
      },
      privacy: {
        text: 'Datenschutz',
      },
      imprint: {
        text: 'Impressum',
      },
    },
  },
  theContact: {
    title: 'Unser Team ist persönlich für Sie da',
    para1: {
      text: 'Sprechen Sie uns an. Gerne beantwortet unser Service-Team Ihre Fragen persönlich oder per E-Mail. Unsere Kundenbetreuung ist {boldText} für Sie da:',
      boldText: 'Montag bis Freitag von 9 bis 18 Uhr',
    },
    para2: {
      text: '{phoneNumber} oder {emailAddress}',
    },
  },
  the404Page: {
    title: 'Fehler 404 - Seite nicht gefunden',
    description:
      'Sollte sie immer noch nicht korrekt angezeigt werden, kehren Sie zur vorigen zurück oder kontaktieren Sie uns unter {phone} oder {email}',
  },
  creditReformModalContent: {
    title: 'Creditreform',
    content: {
      para1:
        'Zur Bereitstellung der sog. Autofill-Funktion und zur Durchführung einer Vorabprüfung erhält die ING relevante Informationen (z.B. Adressdaten, allgemeine Unternehmensinformationen) von der {boldContent1} Bleichstraße 59, 60313 Frankfurt (Main) (im Folgenden „Crefo“). Nach erfolgreicher Vorabprüfung übermittelt die ING Daten (Name, Adresse und gegebenenfalls Geburtsdatum) zum Zwecke der Bonitätsprüfung an die Crefo. Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 Buchstabe b und f der Datenschutz- Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 Buchstabe f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der ING oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.',
      boldContent1: 'Creditreform Frankfurt Emil Vogt KG;',
      para2:
        '{boldContent2} Detaillierte Informationen zur Crefo im Sinne des Artikels 14 DSGVO, d.h. Informationen zum Geschäftszweck, zu Zwecken der Datenspeicherung, zu den Datenempfängerinnen oder Datenempfängern, zum Selbstauskunftsrecht, zum Anspruch auf Löschung oder Berichtigung etc. können online unter {linkText} eingesehen werden.',
      boldContent2:
        'Die Kundin oder der Kunde befreit die ING insoweit auch vom Bankgeheimnis.',
      linkText: 'Datenschutz | Creditreform Frankfurt am Main',
    },
  },
  schufaModalContent: {
    title: 'SCHUFA',
    content: {
      para1:
        'Die ING übermittelt im Rahmen dieses Vertragsverhältnisses erhobene personenbezogene Daten über die Beantragung, die Durchführung und Beendigung dieser Geschäftsbeziehung sowie Daten über nicht vertragsgemäßes Verhalten oder betrügerisches Verhalten an die {boldContent1} Kormoranweg 5, 65201 Wiesbaden (im Folgenden „SCHUFA“). Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 lit. b und Artikel 6 Absatz 1 lit. f der Datenschutz-Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 lit. f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der ING oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.',
      boldContent1: 'SCHUFA Holding AG,',
      para2:
        '{boldContent2} Die SCHUFA verarbeitet die erhaltenen Daten und verwendet sie auch zum Zwecke der Profilbildung (Scoring), um ihren Vertragspartnerinnen und Vertragspartnern im Europäischen Wirtschaftsraum und in der Schweiz sowie ggf. weiteren Drittländern (sofern zu diesen ein Angemessenheitsbeschluss der Europäischen Kommission besteht oder Standardvertragsklauseln vereinbart wurden, die unter {linkText1} eingesehen werden können) Informationen unter anderem zur Beurteilung der Kreditwürdigkeit von natürlichen Personen zu geben. Nähere Informationen zur Tätigkeit der SCHUFA können dem SCHUFA-Informationsblatt nach Art. 14 DSGVO entnommen oder online unter {linkText2} eingesehen werden.',
      boldContent2:
        'Die Kundin oder der Kunde befreit die ING insoweit auch vom Bankgeheimnis.',
      linkText1: 'www.schufa.de',
      linkText2: 'www.ing.de/datenschutz/schufa',
    },
  },
};
