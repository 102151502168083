import axios from 'axios';

export const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const subscribeNewsletter = async (payloadData) => {
  const VUE_APP_FUNNEL_GATEWAY_API = process.env.VUE_APP_FUNNEL_GATEWAY_API;

  const dataToSend = JSON.stringify(payloadData);
  try {
    await axios.post(
      `${VUE_APP_FUNNEL_GATEWAY_API}/subscriptions/newsletters`,
      dataToSend,
      config
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const confirmNewsletterSubscription = async (payloadData) => {
  const VUE_APP_FUNNEL_GATEWAY_API = process.env.VUE_APP_FUNNEL_GATEWAY_API;

  const dataToSend = JSON.stringify(payloadData);
  try {
    await axios.patch(
      `${VUE_APP_FUNNEL_GATEWAY_API}/subscriptions/newsletters/confirmation`,
      dataToSend,
      config
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const registerWaitingList = async (payloadData) => {
  const VUE_APP_FUNNEL_GATEWAY_API = process.env.VUE_APP_FUNNEL_GATEWAY_API;

  const dataToSend = JSON.stringify(payloadData);
  try {
    await axios.post(
      `${VUE_APP_FUNNEL_GATEWAY_API}/subscriptions/waitinglist`,
      dataToSend,
      config
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const confirmWaitingListRegistration = async (payloadData) => {
  const VUE_APP_FUNNEL_GATEWAY_API = process.env.VUE_APP_FUNNEL_GATEWAY_API;

  const dataToSend = JSON.stringify(payloadData);
  try {
    await axios.patch(
      `${VUE_APP_FUNNEL_GATEWAY_API}/subscriptions/warteliste/confirmation`,
      dataToSend,
      config
    );
  } catch (e) {
    throw new Error(e);
  }
};
