import * as commons from './commons';
import * as ing from './ing';
import assets from './ing/assets';
import smeConfirmationCheckbox from './ing/smeConfirmation';
import * as applicant from './applicantValidators';
import * as partner from './partnerValidators';
import * as signers from './signersValidators';
import bookkeepingStandard from './partnerValidators/bookkeepingStandard';
import StateValidator from './state-validator';
import * as becomePartner from './becomePartnerValidators';
import * as subscription from './subscriptionsValidators';
import * as ident from './identValidators';

const applicantFormValidators = Object.assign({}, commons, applicant);
const ingApplicantFormValidators = Object.assign({}, commons, ing);
const applicantFormWithBookKeepingValidators = Object.assign(
  {},
  applicantFormValidators,
  { bookkeepingStandard }
);
const ingApplicantFormWithBookKeepingValidators = Object.assign(
  {},
  ingApplicantFormValidators,
  { bookkeepingStandard }
);
const partnerFormValidators = Object.assign({}, commons, {
  bookkeepingStandard,
});
const partnerInformationValidators = Object.assign({}, commons, partner);
const subscriptionValidators = Object.assign({}, commons, subscription);
const WaitingListValidatorsRegistration = Object.assign(
  {},
  commons,
  subscription
);

const signersRegularValidators = Object.assign(
  {},
  signers,
  ing,
  {
    assets,
    smeConfirmationCheckbox,
  },
  { companyName: commons.company },
  { nationality: commons.nationality },
  { street: commons.street },
  { streetNumber: commons.streetNumber },
  { city: commons.city }
);
const signersMicroValidators = Object.assign(
  {},
  signers,
  ing,
  {
    smeConfirmationCheckbox,
  },
  { companyName: commons.company },
  { nationality: commons.nationality },
  { street: commons.street },
  { streetNumber: commons.streetNumber },
  { city: commons.city },
  { countryOfIncorporation: null },
  { noOtherMDCheckbox: null }
);

const becomePartnerFormValidators = Object.assign({}, commons, becomePartner);

const identValidators = Object.assign({}, commons, ident);

export const applicantSignupFormsValidator = (state) =>
  StateValidator(applicantFormValidators, state);
export const ingApplicantSignupFormsValidator = (state) =>
  StateValidator(ingApplicantFormValidators, state);
export const applicantFormWithBookKeepingValidator = (state) =>
  StateValidator(applicantFormWithBookKeepingValidators, state);
export const ingApplicantFormWithBookKeepingValidator = (state) =>
  StateValidator(ingApplicantFormWithBookKeepingValidators, state);
export const partnerSignupFormsValidator = (state) =>
  StateValidator(partnerFormValidators, state);
export const signersRegularValidator = (state) =>
  StateValidator(signersRegularValidators, state);
export const signersMicroValidator = (state) =>
  StateValidator(signersMicroValidators, state);
export const partnerInformationValidator = (state) =>
  StateValidator(partnerInformationValidators, state);
export const subscriptionValidator = (state) =>
  StateValidator(subscriptionValidators, state);
export const waitingListValidatorsRegistrationValidator = (state) =>
  StateValidator(WaitingListValidatorsRegistration, state);

export const becomePartnerFormValidator = (state) =>
  StateValidator(becomePartnerFormValidators, state);
export const identValidator = (state) => StateValidator(identValidators, state);
