<template>
  <WaitingListConfirmation />
</template>
<script>
  import WaitingListConfirmation from '@/components/subscription/WaitingListConfirmation';

  export default {
    name: 'WaitingListConfirmationPage',
    components: { WaitingListConfirmation },
  };
</script>
