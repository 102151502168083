export default {
  waitingListPage: {
    meta: {
      title: 'Geschäftskonto: jetzt registrieren',
      description:
        'Registrieren Sie sich unverbindlich für unser Geschäftskonto und wir informieren Sie, sobald Sie starten können.',
    },
    form: {
      title: 'Unser Geschäftskonto kommt!',
      para1: 'Möchten Sie informiert werden, sobald unser Geschäftskonto startklar für Sie ist?',
      para2:
        'Dann geben Sie hier einfach Ihre E-Mail-Adresse, Ihren Namen sowie den Namen Ihres Unternehmens und die Unternehmensform ein. ',
      para3:
        'Wir informieren Sie, sobald Sie mit dem Geschäftskonto starten können.',
      para4:
        'Wir schicken Ihnen nach Ihrer Registrierung eine E-Mail mit einem Link, über den Sie uns erlauben, Ihnen weitere Nachrichten zu schicken. ',
      para5: {
        label: 'Bitte beachten Sie unsere {linkText}.',
        linkText: 'Datenschutzerklärung für Geschäftskundinnen und -kunden',
      },
      email: {
        label: 'Ihre E-Mail-Adresse',
      },
      firstName: {
        label: 'Ihr Vorname',
      },
      lastName: {
        label: 'Ihr Nachname',
      },
      companyName: {
        label: 'Ihr Unternehmensname',
      },
      legalForm: {
        label: 'Ihre Unternehmensform',
      },
      submit: 'Jetzt registrieren',
    },
    success: {
      header: {
        title:
          'Vielen Dank für Ihre Anmeldung – bitte schauen Sie einmal in Ihr Postfach',
      },
      body: {
        part1: {
          text: 'Dort sollten Sie innerhalb der nächsten Minuten eine E-Mail von uns bekommen. Mit einem Klick auf den Link erlauben Sie uns, Ihnen E-Mails mit Neuigkeiten und Infos rund um das Geschäftskonto zu schicken. Sie müssen keine weiteren Daten eingeben. ',
        },
        part2: {
          text: '{boldContent} Vielleicht ist sie im Spam-Ordner gelandet. Schauen Sie dort bitte einmal nach.',
          boldContent: 'Keine E-Mail erhalten?',
        },
        part3: {
          label:
            'Mehr Wissenswertes für Ihr Unternehmen finden Sie auf unserer Website:{linkText}',
          linkText: 'Zum Business Banking',
        },
      },
    },
  },
};
